<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Lista de inspecciones</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div id="container" v-if="inspections.length > 0">
        <ion-list v-for="item in inspections" :key="item.id">
          <ion-item detail @click="handleItemClick(item)">
            <ion-label>
              <h2>{{ item.document_number }}</h2>
              <p>{{ item.date }}</p>
            </ion-label>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>
<script setup>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonTitle,
  onIonViewWillEnter,
  loadingController,
} from "@ionic/vue";
import {useRouter, useRoute} from "vue-router";
import {ref} from "vue";
import {get} from "../services";

// Data
const router = useRouter();
const route = useRoute();
const inspections = ref([]);

// Methods
const handleItemClick = (item) => {
  router.push({
    name: "inspection-report",
    params: {
      inspection_id: item.id,
    },
  });
};

const showLoading = async () => {
  const loading = await loadingController.create({
    message: "Cargando...",
    duration: 10000,
  });

  loading.present();
};

const hideLoading = async () => {
  await loadingController.dismiss();
};

onIonViewWillEnter(async () => {
  showLoading();
  const id = route.params.grower_id;
  const params = {
    no_page: true,
  };
  try {
    const res = await get(`grower_inspections/${id}/`, params);
    inspections.value = res.data;
  } catch (error) {
    console.log(error);
  } finally {
    hideLoading();
  }
});
</script>
