<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar v-if="$route.query.hideToolbar !== 'true'">
        <ion-buttons>
          <ion-button @click="backToList" v-if="item">
            Lista
            <ion-icon :icon="chevronBack" slot="start"></ion-icon>
          </ion-button>
          <ion-title>
            Inspección {{ $route.params.inspection_number }}
          </ion-title>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div id="container" v-if="item">
        <div id="home" v-show="activeSegment === 'home'">
          <ion-item>
            <h1>Inspección</h1>
            <template
              v-for="(lot, lotIndex) in item.inspectiondetail_set"
              :key="lotIndex"
            >
              <ion-badge
                slot="end"
                :style="`background-color: ${lot.qc_color}`"
                >{{ lot.qc_classification }}</ion-badge
              >
            </template>
          </ion-item>
          <ion-list>
            <ion-item>
              <ion-label>ID</ion-label>
              <ion-note slot="end">{{ item.number }}</ion-note>
            </ion-item>
            <ion-item>
              <ion-label>Documento</ion-label>
              <ion-note slot="end">{{ item.document_number }}</ion-note>
            </ion-item>
            <ion-item>
              <ion-label>Productor</ion-label>
              <ion-note slot="end">{{ item.grower.name }}</ion-note>
            </ion-item>
            <ion-item>
              <ion-label>CSG</ion-label>
              <ion-note slot="end">{{ item.grower.csg }}</ion-note>
            </ion-item>
            <ion-item>
              <ion-label>Ciudad</ion-label>
              <ion-note slot="end">{{ item.grower.city }}</ion-note>
            </ion-item>
            <ion-item>
              <ion-label>Provincia</ion-label>
              <ion-note slot="end">{{ item.grower.province }}</ion-note>
            </ion-item>
            <ion-item>
              <ion-label>Tipo</ion-label>
              <ion-note slot="end">{{ item.inspection_type }}</ion-note>
            </ion-item>
            <ion-item>
              <ion-label>Fecha</ion-label>
              <ion-note slot="end">{{ item.date }}</ion-note>
            </ion-item>
            <ion-template
              v-for="other in item.evaluation.data.other_inputs.inspection"
              :key="other.key"
            >
              <ion-item>
                <ion-label>{{ other.label }}</ion-label>
                <ion-note slot="end">{{ item.data[other.key] }}</ion-note>
              </ion-item>
            </ion-template>
          </ion-list>
          <ion-button
            expand="block"
            @click.prevent="shareViaWebShare"
            v-if="webShareApiSupported"
          >
            Compartir
            <ion-icon slot="end" :icon="share"></ion-icon>
          </ion-button>
        </div>

        <div id="list" v-show="activeSegment === 'list'">
          <ion-item>
            <h1>Lotes</h1>
          </ion-item>
          <ion-accordion-group
            v-for="lot in item.inspectiondetail_set"
            :key="lot.id"
          >
            <ion-accordion value="first">
              <ion-item slot="header">
                <ion-label>Lote {{ lot.lot }}</ion-label>
                <ion-icon
                  :icon="ellipse"
                  :style="`color: ${lot.qc_color}`"
                  slot="start"
                  >{{ lot.qc_classification }}</ion-icon
                >
              </ion-item>
              <div slot="content">
                <ion-item>
                  <ion-label>Muestra</ion-label>
                  <ion-note slot="end">{{ lot.sample }}</ion-note>
                </ion-item>
                <ion-item>
                  <ion-label>Kilos</ion-label>
                  <ion-note slot="end">{{ lot.net_kg }}</ion-note>
                </ion-item>
                <ion-item>
                  <ion-label>Clasificación</ion-label>
                  <ion-note slot="end">{{ lot.qc_classification }}</ion-note>
                </ion-item>
                <ion-item>
                  <ion-label>Suma de defectos </ion-label>
                  <ion-note slot="end">{{ lot.qc_value }}</ion-note>
                </ion-item>

                <ion-item>
                  <ion-label>Color</ion-label>
                  <ion-note slot="end">{{ lot.qc_color }}</ion-note>
                </ion-item>
                <ion-template
                  v-for="ida in item.evaluation.data.other_inputs
                    .inspection_detail"
                  :key="ida.key"
                >
                  <ion-item>
                    <ion-label>{{ ida.label }}</ion-label>
                    <ion-note slot="end">{{ lot.data[ida.key] }}</ion-note>
                  </ion-item>
                </ion-template>
                <ion-item-group
                  v-for="k in item.evaluation.data.defect_types"
                  :key="k.key"
                >
                  <ion-item-divider :style="{'font-size': '16px'}">
                    <ion-note
                      slot="end"
                      :style="`color: ${
                        lot.data['c_' + k.key]
                      }; font-size: 16px;`"
                      >{{ lot.data["p_" + k.key] }}</ion-note
                    >
                    <ion-label> {{ k.label }}</ion-label>
                  </ion-item-divider>
                  <ion-item v-for="defect in k.defects" :key="defect.key">
                    <ion-icon
                      :icon="ellipse"
                      :style="`color: ${
                        lot.data['c_' + defect.key]
                      }; font-size: 10px;`"
                      slot="start"
                    ></ion-icon>
                    <ion-label>
                      {{ defect.label }}
                    </ion-label>
                    <ion-note slot="end">{{
                      lot.data["p_" + defect.key]
                    }}</ion-note>
                  </ion-item>
                </ion-item-group>
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </div>
        <div id="image" v-show="activeSegment === 'image'">
          <ion-item>
            <h1>Imagenes</h1>
          </ion-item>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-segment
          :value="activeSegment"
          @ion-change="($event) => (activeSegment = $event.target.value)"
        >
          <ion-segment-button value="home">
            <ion-icon :icon="home"></ion-icon>
          </ion-segment-button>
          <ion-segment-button value="list">
            <ion-icon :icon="list"></ion-icon>
          </ion-segment-button>
          <ion-segment-button value="image">
            <ion-icon :icon="image"></ion-icon>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script setup>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  IonFooter,
  IonTitle,
  IonButtons,
  IonList,
  IonItem,
  IonBadge,
  IonAccordion,
  IonAccordionGroup,
  IonLabel,
  IonNote,
  IonIcon,
  IonButton,
  IonItemDivider,
  onIonViewWillEnter,
  loadingController,
} from "@ionic/vue";
import {ellipse, home, list, image, share, chevronBack} from "ionicons/icons";
import {useRoute, useRouter} from "vue-router";
import {ref, computed} from "vue";
import {get} from "../services";

const activeSegment = ref("home");
const route = useRoute();
const router = useRouter();

const item = ref(null);

const backToList = () => {
  router.push({
    name: "inspection-list",
    params: {
      grower_id: item.value.grower.id,
    },
  });
};

const webShareApiSupported = computed(() => {
  return navigator.share !== undefined;
});

const shareViaWebShare = () => {
  navigator.share({
    title: "Qplus",
    text: "Inspección",
    url: route.fullPath,
  });
};

const showLoading = async () => {
  const loading = await loadingController.create({
    message: "Cargando...",
    duration: 10000,
  });

  loading.present();
};

const hideLoading = async () => {
  await loadingController.dismiss();
};

onIonViewWillEnter(async () => {
  showLoading();
  const id = route.params.inspection_id;
  const params = {
    expand:
      "grower,evaluation,inspectiondetail,inspection_set,inspectiondetail_set",
  };
  try {
    const res = await get(`inspection_report/${id}/`, params);
    item.value = res.data;
  } catch (error) {
    console.log(error);
  } finally {
    hideLoading();
  }
});
</script>

<style scoped>
ion-badge {
  --padding-end: 20px;
  --padding-start: 20px;
  --padding-top: 5px;
  --padding-bottom: 5px;
}
</style>
