import axios from "axios";
// import { handleError, showErrorNotification } from "./errors";

const api = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
api.defaults.timeout = 60000;
// api.interceptors.request.use(
//   (config) => {
//     const token = "7d4204e3d89a0e15329eadde207cf4751a061c87";
//     // const token = "14bf5ee2a62ba7964d690fcf097d15d1ef77882d";

//     if (token) {
//       // token = JSON.parse(token);
//       // eslint-disable-next-line no-param-reassign
//       if (token) config.headers.Authorization = `Token ${token}`;
//     }

//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export default api;
