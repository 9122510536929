import {createRouter, createWebHashHistory} from "@ionic/vue-router";
import InspectionReport from "../views/InspectionReport.vue";
import InspectionList from "../views/InspectionList.vue";

const routes = [
  {
    path: "/",
    redirect: "/inspections/idmula",
  },
  {
    path: "/inspections/:grower_id",
    name: "inspection-list",
    component: InspectionList,
  },
  {
    path: "/inspection/:inspection_id",
    name: "inspection-report",
    component: InspectionReport,
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

export default router;
